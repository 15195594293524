import React, {useState} from 'react';
import Resume from './Resume';
import PracticeResume from './PracticeResume';
import ReadData from './ReadData';
import './App.css';
import { CSVLink, CSVDownload } from 'react-csv';
import { HashRouter, Route, Link } from 'react-router-dom';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
            <HashRouter>
                {/*study 1*/}
                <Route 
                    path='/v1r1m'
                    render={(props) => (
                        <Resume {...props} studyVersion={1} resumeVersion={1} gender={"male"}/>
                    )}
                />
                <Route 
                    path='/v1r1f'
                    render={(props) => (
                        <Resume {...props} studyVersion={1} resumeVersion={1} gender={"female"}/>
                    )}
                />
                <Route 
                    path='/v1r2'
                    render={(props) => (
                        <Resume {...props} studyVersion={1} resumeVersion={2}/>
                    )}
                />
                {/*study 1b*/}
                <Route 
                    path='/v1br1m'
                    render={(props) => (
                        <Resume {...props} studyVersion={"1b"} resumeVersion={1} gender={"male"}/>
                    )}
                />
                <Route 
                    path='/v1br1f'
                    render={(props) => (
                        <Resume {...props} studyVersion={"1b"} resumeVersion={1} gender={"female"}/>
                    )}
                />
                <Route 
                    path='/v1br2'
                    render={(props) => (
                        <Resume {...props} studyVersion={"1b"} resumeVersion={2}/>
                    )}
                />
                {/*study 2*/}
                <Route 
                    path='/v2r1m'
                    render={(props) => (
                        <Resume {...props} studyVersion={2} resumeVersion={1} gender={"male"}/>
                    )}
                />
                <Route 
                    path='/v2r1f'
                    render={(props) => (
                        <Resume {...props} studyVersion={2} resumeVersion={1} gender={"female"}/>
                    )}
                />
                <Route 
                    path='/v2r2'
                    render={(props) => (
                        <Resume {...props} studyVersion={2} resumeVersion={2}/>
                    )}
                />
                {/*study 2b*/}
                <Route 
                    path='/v2br1m'
                    render={(props) => (
                        <Resume {...props} studyVersion={"2b"} resumeVersion={1} gender={"male"}/>
                    )}
                />
                <Route 
                    path='/v2br1f'
                    render={(props) => (
                        <Resume {...props} studyVersion={"2b"} resumeVersion={1} gender={"female"}/>
                    )}
                />
                <Route 
                    path='/v2br2'
                    render={(props) => (
                        <Resume {...props} studyVersion={"2b"} resumeVersion={2}/>
                    )}
                />
                {/*study 3*/}
                <Route 
                    path='/v3r1m'
                    render={(props) => (
                        <Resume {...props} studyVersion={3} resumeVersion={1} gender={"male"}/>
                    )}
                />
                <Route 
                    path='/v3r1f'
                    render={(props) => (
                        <Resume {...props} studyVersion={3} resumeVersion={1} gender={"female"}/>
                    )}
                />
                <Route 
                    path='/v3r2'
                    render={(props) => (
                        <Resume {...props} studyVersion={3} resumeVersion={2}/>
                    )}
                />
                <Route 
                    path='/practice'
                    render={(props) => (
                        <PracticeResume {...props} version={"1b"}/>
                    )}
                />
                <Route 
                    path='/practice_extensive'
                    render={(props) => (
                        <PracticeResume {...props} version={"extensive"}/>
                    )}
                />
                <Route 
                    path='/admin_activity'
                    render={(props) => (
                        <ReadData {...props} displayingActivity={true} displayingResume={false} displayingMouse={false}/>
                    )}
                />
                <Route 
                    path='/admin_resume'
                    render={(props) => (
                        <ReadData {...props} displayingResume={true} displayingActivity={false} displayingMouse={false}/>
                    )}
                />
                <Route 
                    path='/admin_mouse'
                    render={(props) => (
                        <ReadData {...props} displayingMouse={true} displayingResume={false} displayingActivity={false}/>
                    )}
                />
            </HashRouter>
            </div>
        );
    }
}

export default App;
